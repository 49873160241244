import React, { useEffect, useState } from "react";
import "./singamsTv.scss";
import {
  mbSingamsTvBanner,
  playIcon,
  singamsTvBanner,
  yellowTexture,
} from "../../images";
import Banner from "../../components/Banner/Banner";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useWindowSize } from "react-use";
import { singamsTvMain, singamsTvData } from "../../data/singamsTvData";
import FsLightbox from "fslightbox-react";
import ContactUs from "../../components/ContactUs/ContactUs";
import WhatsApp from "../../components/whatsApp/whatsApp";

const SingamsTv = () => {
  const { width } = useWindowSize();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const singamsTvList = singamsTvData.map((item, i) => (
    <div
      className="col-md-6 col-lg-4 col-sm-6 singams_col"
      key={`tv-id-${i}`}
      data-aos="fade-up"
    >
      <div className="singams_box1">
        <div
          className="img_wrapper"
          onClick={() => handleWatchClick(item?.link)}
        >
          <img
            src={item.img}
            alt="Media"
            className="singams_img"
            loading="lazy"
          />
          <img
            src={playIcon}
            alt="play icon"
            className="play_icon"
            loading="lazy"
          />
          <p className="video_time">{item.time}</p>
        </div>
        <div className="content_wrapper">
          <h3 className="video_title">{item.title}</h3>
        </div>
      </div>
    </div>
  ));

  const handleWatchClick = (videoLink) => {
    setIsVideoModalOpen(!isVideoModalOpen);
    setVidLink(videoLink);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="singamstv_sec1">
        <Banner
          linkText="Singams tv"
          bannerImg={width > 767 ? singamsTvBanner : mbSingamsTvBanner}
          disable
        />
      </section>

      <section className="singamstv_sec2">
        <img
          src={yellowTexture}
          alt="yellow texture"
          className="yellow_back_img"
        />

        <div className="my_container">
          <div className="singams_wrapper">
            <h2 className="heading" data-aos="fade-up">
              SINGAMS TV
            </h2>
            <div className="singams_box" data-aos="fade-up">
              <div
                className="img_wrapper"
                onClick={() => handleWatchClick(singamsTvMain[0].link)}
              >
                <img
                  src={
                    width > 767 ? singamsTvMain[0].img : singamsTvMain[0].mbImg
                  }
                  alt="Media"
                  className="singams_img"
                />
                <img src={playIcon} alt="play icon" className="play_icon" />
              </div>
              <div className="content_wrapper">
                <h3 className="video_title">{singamsTvMain[0].title}</h3>
                <p className="video_time">{singamsTvMain[0].time}</p>
              </div>
            </div>
          </div>
          <h3 className="heading vid_heading" data-aos="fade-up">
            MORE VIDEOS
          </h3>
          <div className="row singams_row" data-aos="fade-up">
            {singamsTvList}
          </div>
        </div>
      </section>

      <section className="singamstv_lightbox_wrapper">
        <FsLightbox toggler={isVideoModalOpen} sources={[vidLink]} />
      </section>
      
      <WhatsApp />
      <ContactUs />
      <Footer />
    </>
  );
};

export default SingamsTv;
