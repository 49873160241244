import React, { useEffect } from "react";
import "./media.scss";
import { MediaBanner, mbMediaBanner, yellowTexture } from "../../images";
import Banner from "../../components/Banner/Banner";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useWindowSize } from "react-use";
import { mediaData } from "../../data/mediaData";
import ContactUs from "../../components/ContactUs/ContactUs";
import WhatsApp from "../../components/whatsApp/whatsApp";

const Media = () => {
  const { width } = useWindowSize();

  const mediaList = mediaData.map(
    (item, i) =>
      item.id !== 1 && (
        <a
          className="col-md-6 col-lg-4 col-sm-6 media_col"
          key={i}
          data-aos="fade-up"
          href={item.link}
          target="_blank"
          rel="noreferrer"
        >
          <div className="media_box1">
            <div className="img_wrapper">
              <img src={item.img} alt="Media" className="media_img" />
              {/* <img src={item.logo} alt="Media logo" className="media_logo" /> */}
            </div>
            <div className="content_wrapper">
              <h3 className="media_title">{item.title}</h3>
              <div className="link_wrapper">
                <div className="media_link">Know More</div>
                <p className="media_date">{item.date}</p>
              </div>
            </div>
          </div>
        </a>
      )
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="media_sec1">
        <Banner
          linkText="Media"
          bannerImg={width > 767 ? MediaBanner : mbMediaBanner}
          disable
        />
      </section>

      <section className="media_sec2">
        <img
          src={yellowTexture}
          alt="yellow texture"
          className="yellow_back_img"
        />

        <div className="my_container">
          <div className="media_wrapper">
            <h2 className="heading" data-aos="fade-up">
              PRESS ARTICLES
            </h2>
            <a
              className="media_box"
              data-aos="fade-up"
              href={mediaData[0].link}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={width > 767 ? mediaData[0].img : mediaData[0].mbImg}
                alt="Media"
                className="media_img"
                loading="lazy"
              />
              <div className="content_wrapper">
                <h3 className="media_title">{mediaData[0].title}</h3>
                <div className="link_wrapper">
                  <div className="media_link">Know More</div>
                  <p className="media_date">{mediaData[0].date}</p>
                </div>
              </div>
            </a>
          </div>
          <div className="row media_row" data-aos="fade-up">
            {mediaList}
          </div>
        </div>
      </section>
      <WhatsApp />
      <ContactUs />
      <Footer />
    </>
  );
};

export default Media;
