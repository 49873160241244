import React, { useEffect, useState } from "react";
import "./header.scss";
import { useWindowSize } from "react-use";
import {
  fixtureURL,
  homepageURL,
  scoreURL,
  squadURL,
  statsURL,
} from "../helpers/constant-words";
import { Link, NavLink, useLocation } from "react-router-dom";
import { grass, isplLogo } from "../../images";
import HeaderNavItem from "./HeaderNavItem";
import { Button } from "react-scroll";
const body = document.querySelector("body");

const navLinksArr = [
  {
    id: 1,
    type: "img",
    img: isplLogo,
    mainLink: homepageURL,
    class: "logo_img",
  },
  {
    id: 4,
    type: "text",
    mainTitle: "Stats",
    mainLink: statsURL,
  },
  {
    id: 5,
    type: "text",
    mainTitle: "Fixtures",
    mainLink: fixtureURL,
  },
  {
    id: 6,
    type: "text",
    mainTitle: "Score",
    mainLink: scoreURL,
  },
  {
    id: 7,
    type: "text",
    mainTitle: "Squad",
    mainLink: squadURL,
  },
  {
    id: 8,
    type: "text",
    mainTitle: "About Team",
    mainLink: homepageURL,
    scrollId: "about",
  },
  {
    id: 9,
    type: "text",
    mainTitle: "Upcoming Tournaments",
    mainLink: homepageURL,
    scrollId: "tournaments",
  },
  {
    id: 10,
    type: "text",
    mainTitle: "Owners",
    mainLink: homepageURL,
    scrollId: "owners",
  },
  {
    id: 11,
    type: "button",
    mainTitle: "Contact Us",
    mainLink: homepageURL,
    scrollId: "contact",
  },
];

const mobNavLinksArr = [
  {
    id: 3,
    type: "text",
    mainTitle: "Stats",
    mainLink: statsURL,
  },
  {
    id: 4,
    type: "text",
    mainTitle: "Fixtures",
    mainLink: fixtureURL,
  },
  {
    id: 5,
    type: "text",
    mainTitle: "Score",
    mainLink: scoreURL,
  },
  {
    id: 6,
    type: "text",
    mainTitle: "Squad",
    mainLink: squadURL,
  },
  {
    id: 7,
    type: "text",
    mainTitle: "About Team",
    mainLink: homepageURL,
    scrollId: "about",
  },
  {
    id: 8,
    type: "text",
    mainTitle: "Upcoming Tournaments",
    mainLink: homepageURL,
    scrollId: "tournaments",
  },
  {
    id: 9,
    type: "text",
    mainTitle: "Owners",
    mainLink: homepageURL,
    scrollId: "owners",
  },
  // {
  //   id: 4,
  //   type: "text",
  //   mainTitle: "Contact Us",
  //   mainLink: "javscript:void(0)",
  //   scrollId: "contact",
  // },
  // {
  //   id: 4,
  //   type: "button",
  //   mainTitle: "Contact Us",
  //   mainLink: "javscript:void(0)",
  //   scrollId: "contact",
  // },
];

const Header = () => {
  const { width } = useWindowSize();
  const [navlinkIndex, setNavlinkIndex] = useState(null);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isBg, setIsbg] = useState(false);
  const { pathname: locationPathname } = useLocation();
  const [isButton, setIsButton] = useState();
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    body.style.overflow = "auto";
    // if (locationPathname === "/") {
    //   setIsbg(false);
    // } else {
    //   setIsbg(true);
    // }
    setIsMenuActive(false);
  }, [locationPathname]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  });

  const handleScroll = () => {
    // if (window.location.pathname === "/")
    if (window.pageYOffset > 60) {
      setIsbg(true);
    } else {
      setIsbg(false);
    }
  };

  const handleHamburgerClick = (i) => {
    setIsMenuActive(!isMenuActive);
    if (!isMenuActive) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
    setIsButton(i);
    console.log("isButton", isButton);
  };

  const navlinkList = navLinksArr.map((nav, i) => (
    <li key={i}>
      {nav.type === "text" ? (
        // <NavLink
        //   to={nav.mainLink}
        //   className={`nav_tab ${navlinkIndex === i ? "active" : ""}`}
        // >
        //   {nav.mainTitle}
        // </NavLink>
        nav.scrollId ? (
          <Button
            style={{
              border: "none",
              backgroundColor: "transparent",
              cursor: "default",
            }}
            to={nav.scrollId}
            smooth={true}
            spy={true}
            offset={-50}
            duration={1}
            className="nav_btn"
          >
            <NavLink
              to={`${nav.mainLink}#${nav.scrollId}`}
              className={`nav_tab ${navlinkIndex === i ? "active" : ""}`}
              // onClick={() => setNavlinkIndex(i)}
              onClick={() => setIsButton(i)}
            >
              {nav.mainTitle}
            </NavLink>
            <img src={grass} alt="grass" className="nav_img" />
          </Button>
        ) : (
          <>
            <NavLink
              to={nav.mainLink}
              className={`nav_tab nav_tab_1 ${
                navlinkIndex === i ? "active" : ""
              }`}
              // onClick={() => setNavlinkIndex(i)}
            >
              {nav.mainTitle}
            </NavLink>
          </>
        )
      ) : nav.type === "button" ? (
        <Button
          style={{
            border: "none",
            backgroundColor: "transparent",
          }}
          to={nav.scrollId}
          smooth={true}
          spy={true}
          offset={0}
          duration={1}
          onClick={() => setIsButton(i)}
        >
          <Link
            to={`${nav.mainLink}#${nav.scrollId}`}
            className={`nav_tab ${navlinkIndex === i ? "active" : ""}`}
          >
            <button className="submit_btn">{nav.mainTitle}</button>
          </Link>
        </Button>
      ) : (
        <div
          onClick={() => setNavlinkIndex(i)}
          className={`logo_wrapper ${navlinkIndex === i ? "" : ""} `}
        >
          <Link to={nav.mainLink}>
            <img
              className={nav.class}
              src={nav.img}
              alt="ispl Logo"
              //   onClick={scrollHandler}
            />
          </Link>
        </div>
      )}
    </li>
  ));

  return (
    <>
      <header className={`header_sec1 ${isBg ? "navbar_bg" : ""}`}>
        {width > 1080 ? (
          <>
            <div className="my_container">
              <div className="navlinks">
                <ul>{navlinkList}</ul>
              </div>
            </div>
          </>
        ) : (
          <header className="header">
            <div className="my_container">
              <div className="logo_wrapper">
                <Link className="logo_link" to="/">
                  <img
                    src={isplLogo}
                    alt="ispl logo"
                    className="ispl_logo img-fluid"
                  />
                </Link>
              </div>
            </div>
            <nav className="nav_links_wrapper">
              <div
                className={`nav_line2_wrapper_flex_wrapper ${
                  isMenuActive ? "active" : ""
                }`}
              >
                <div className="logo_wrapper">
                  <Link className="logo_link" to="/">
                    <img
                      src={`${isMenuActive ? isplLogo : isplLogo}`}
                      alt="ispl logo"
                      className="ispl_logo_1 img-fluid"
                    />
                  </Link>
                </div>
                <ul className="nav_line2_wrapper_flex">
                  {mobNavLinksArr.map((navData, i) => (
                    <HeaderNavItem
                      navData={navData}
                      key={parseInt(navData.id)}
                      arrIndex={i}
                      handleHamburgerClick={handleHamburgerClick}
                      activeIndex={activeIndex}
                      setActiveIndex={setActiveIndex}
                      // isButton={i}
                    />
                  ))}
                </ul>
              </div>
            </nav>

            {/* mobile UI start */}
            <div
              className={`hamburger_lines ${
                isMenuActive ? "active" : "" || isBg ? "black" : ""
              }`}
              onClick={handleHamburgerClick}
            >
              <div className="line line1"></div>
              <div className="line line2"></div>
              <div className="line line3"></div>
            </div>
          </header>
        )}
      </header>
    </>
  );
};

export default Header;
