import { images } from "../images";

export const squadData = [
  {
    id: 1,
    img: images.squad01.image,
    alt: images.squad01.alt,
    name: "ANIKET SANAP",
    desc: "LEFT ARM SPINNER",
    details: "33, Mumbai",
  },
  {
    id: 2,
    img: images.squad02.image,
    alt: images.squad02.alt,
    name: "BABLU PATIL",
    desc: "RIGHT HAND BATTER",
    details: "39, Pune",
  },
  {
    id: 3,
    img: images.squad03.image,
    alt: images.squad03.alt,
    name: "DILIP BINJWA",
    desc: "LEFT HAND ALL ROUNDER",
    details: "40, Indore",
  },
  {
    id: 4,
    img: images.squad04.image,
    alt: images.squad04.alt,
    name: "FARHAT AHMED",
    desc: "RIGHT ARM FAST BOWLER",
    details: "33, Srinagar",
  },
  {
    id: 5,
    img: images.squad05.image,
    alt: images.squad05.alt,
    name: "FARMAN KHAN",
    desc: "RIGHT ARM FAST BOWLER",
    details: "28, Karnal",
  },
  {
    id: 6,
    img: images.squad06.image,
    alt: images.squad06.alt,
    name: "HARISH PARMAR",
    desc: "RIGHT HANDED ALL ROUNDER",
    details: "19, Delhi",
  },
  {
    id: 7,
    img: images.squad07.image,
    alt: images.squad07.alt,
    name: "KETAN MHATRE",
    desc: "RIGHT HAND BATTER",
    details: "27, Dombivali",
  },
  {
    id: 8,
    img: images.squad08.image,
    alt: images.squad08.alt,
    name: "PANKAJ PATEL",
    desc: "LEFT ARM FAST BOWLER",
    details: "25, Indore",
  },
  {
    id: 9,
    img: images.squad09.image,
    alt: images.squad09.alt,
    name: "R THAVITH KUMAR",
    desc: "LEFT ARM FAST BOWLER",
    details: "31, Chennai",
  },
  {
    id: 10,
    img: images.squad10.image,
    alt: images.squad10.alt,
    name: "RAJDEEP JADEJA",
    desc: "RIGHT ARM SPINNER",
    details: "29, Gujarat",
  },
  {
    id: 11,
    img: images.squad11.image,
    alt: images.squad11.alt,
    name: "SAGAR ALI",
    desc: "LEFT HAND BATSMAN",
    details: "25, Kolkata",
  },
  {
    id: 12,
    img: images.squad12.image,
    alt: images.squad12.alt,
    name: "SANJAY KANOJJIYA",
    desc: "LEFT HAND BATSMAN",
    details: "27, Delhi",
  },
  {
    id: 13,
    img: images.squad13.image,
    alt: images.squad13.alt,
    name: "SUMEET DHEKALE",
    desc: "LEFT HAND BATSMAN",
    details: "36, Vasai-Virar",
  },
  {
    id: 14,
    img: images.squad14.image,
    alt: images.squad14.alt,
    name: "V VIGNESH",
    desc: "RIGHT ARM ALL ROUNDER",
    details: "25, Chennai",
  },
  {
    id: 15,
    img: images.squad15.image,
    alt: images.squad15.alt,
    name: "VEDANT MAYEKAR",
    desc: "RIGHT ARM ALL ROUNDER",
    details: "18, Mumbai",
  },
  {
    id: 16,
    img: images.squad16.image,
    alt: images.squad16.alt,
    name: "VISHWANATH JADHAV",
    desc: "RIGHT ARM ALL ROUNDER",
    details: "28, Vasai-Virar",
  },
];
