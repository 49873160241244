import React from "react";
import "./whatsapp.scss";
import { whatsAppIcon } from "../../images";


const WhatsApp = () => {
    return (
        <>
        <section className="whatsapp_sec1">
            <a href="https://wa.me/+918169575251" target="_blank" className="whatsapp_link" rel="noreferrer">
                <img src={whatsAppIcon} alt="" className="whatsapp_img"/>
            </a>
        </section>
        </>
    );
};

export default WhatsApp;