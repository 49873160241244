// general
export { default as isplLogo } from "./general/chennai_logo.png";
export { default as mbHeaderTexture } from "./general/mb_yellow_texture.png";
export { default as mailIcon } from "./general/mail.png";
export { default as locationIcon } from "./general/location.png";
export { default as youtubeIcon } from "./general/youtube.svg";
export { default as facebookIcon } from "./general/facebook.svg";
export { default as instaIcon } from "./general/instagram.svg";
export { default as twitterIcon } from "./general/twitter.png";
export { default as bellIcon } from "./general/bell.png";
export { default as linkedIn } from "./general/linkedIn.png";
export { default as whatsAppIcon } from "./general/whatspp_icon.png";
export { default as yellowTexture } from "./general/yellow_texture.png";
export { default as homeIcon } from "./general/home_icon.png";
export { default as playIcon } from "./general/Play_Icon.svg";

// homepage
export { default as desktopBanner } from "./home/banner_1.png";
export { default as bannerVideo1 } from "./home/banner-video-1.mp4";
export { default as mbBannerVideo1 } from "./home/mb-banner-video-1.mp4";
export { default as yellowBack } from "./home/yellow_background.png";
export { default as whiteBack } from "./home/white_back.png";
export { default as mbWhiteBack } from "./home/mb_white_back.png";
export { default as chennaiLogo } from "./home/chennai_singams_logo.png";
export { default as ball } from "./home/ball.png";
export { default as grass } from "./home/title_bottom.png";
export { default as upcomingBack } from "./home/upcoming_back.png";
export { default as mbUpcomingBack } from "./home/mb_upcoming_back.png";
export { default as sonyLiv } from "./home/sony_liv.png";
export { default as sony6 } from "./home/sony6.png";
export { default as sony10 } from "./home/sony10.png";
export { default as plusSign } from "./home/elements.png";
export { default as whiteCircle } from "./home/Ellipse 3.png";
export { default as productBall } from "./home/product_14.png";
export { default as rightArrow } from "./home/right_arrow.png";
export { default as leftArrow } from "./home/left_arrow.png";
export { default as bat } from "./home/bat.png";
export { default as leagueImg } from "./home/leageue_2.png";
export { default as mbLeagueImg } from "./home/mb_leageue_2.png";
export { default as galleryFade } from "./home/yellow_fade.png";
export { default as bannerLogo1 } from "./home/banner_logo_1.png";
export { default as bannerLogo2 } from "./home/banner_logo_2.png";

// Fixture images
export { default as fixtureBanner } from "./fixture/fixture_banner.png";
export { default as whiteHeadBottom } from "./fixture/white-head-bottom.png";
export { default as vsImg } from "./fixture/vs_img.png";
export { default as dividerBat } from "./fixture/bat.png";

// squad images
export { default as squadBanner } from "./squad/squad_banner.png";
export { default as mbSquadBanner } from "./squad/mb_squad_banner_2.jpg";
export { default as squadBackImg } from "./squad/white_back.png";

// stats images
// squad images
export { default as statsBanner } from "./stats/stats_banner.jpg";
export { default as mbStatsBanner } from "./stats/mb_stats_banner.jpg";
export { default as statsyellowBack } from "./stats/Yellow_texture.jpg";
export { default as statsWhiteBack } from "./stats/stats_white_back.png";
export { default as mbStatsWhiteBack } from "./stats/mb_stats_white_back.png";
export { default as divBatStats } from "./stats/divider_bat.png";

// Media images
export { default as MediaBanner } from "./media/media_banner.jpg";
export { default as mbMediaBanner } from "./media/mb_media_banner.jpg";

// Singams Tv images
export { default as singamsTvBanner } from "./singams-tv/singamstv_banner.jpg";
export { default as mbSingamsTvBanner } from "./singams-tv/mb_singamstv_banner.jpg";

export let images = {
  banner1: {
    image: require("./home/banner_3.png"),
    alt: "banner",
  },
  banner2: {
    image: require("./home/banner_4.png"),
    alt: "banner",
  },
  mbBanner1: {
    image: require("./home/mb_banner_3.png"),
    alt: "banner",
  },
  mbBanner2: {
    image: require("./home/mb_banner_4.png"),
    alt: "banner",
  },
  sandip: {
    image: require("./home/sandip.png"),
    alt: "banner",
  },
  // sandip: {
  //   image: require("./home/sandip.png"),
  //   alt: "sandip",
  // },
  rajdip: {
    image: require("./home/Rajdip.png"),
    alt: "Rajdip",
  },
  surya: {
    image: require("./home/suriya.png"),
    alt: "suriya",
  },
  gallery_1: {
    image: require("./home/gallery/gallery_1.jpg"),
    alt: "gallery",
  },
  galleryMobile_1: {
    image: require("./home/gallery/mb_gallery_1.jpg"),
    alt: "gallery",
  },
  galleryThumb_1: {
    image: require("./home/gallery/thumb_gallery_1.png"),
    alt: "gallery",
  },
  gallery_2: {
    image: require("./home/gallery/gallery_2.jpg"),
    alt: "gallery",
  },
  galleryMobile_2: {
    image: require("./home/gallery/mb_gallery_2.jpg"),
    alt: "gallery",
  },
  galleryThumb_2: {
    image: require("./home/gallery/thumb_gallery_2.png"),
    alt: "gallery",
  },
  gallery_3: {
    image: require("./home/gallery/gallery_3.jpg"),
    alt: "gallery",
  },
  galleryMobile_3: {
    image: require("./home/gallery/mb_gallery_3.jpg"),
    alt: "gallery",
  },
  galleryThumb_3: {
    image: require("./home/gallery/thumb_gallery_3.png"),
    alt: "gallery",
  },
  gallery_4: {
    image: require("./home/gallery/gallery_4.jpg"),
    alt: "gallery",
  },
  galleryMobile_4: {
    image: require("./home/gallery/mb_gallery_4.jpg"),
    alt: "gallery",
  },
  galleryThumb_4: {
    image: require("./home/gallery/thumb_gallery_4.png"),
    alt: "gallery",
  },
  gallery_5: {
    image: require("./home/gallery/gallery_5.jpg"),
    alt: "gallery",
  },
  galleryMobile_5: {
    image: require("./home/gallery/mb_gallery_5.jpg"),
    alt: "gallery",
  },
  galleryThumb_5: {
    image: require("./home/gallery/thumb_gallery_5.png"),
    alt: "gallery",
  },
  gallery_6: {
    image: require("./home/gallery/gallery_6.jpg"),
    alt: "gallery",
  },
  galleryMobile_6: {
    image: require("./home/gallery/mb_gallery_6.jpg"),
    alt: "gallery",
  },
  galleryThumb_6: {
    image: require("./home/gallery/thumb_gallery_6.png"),
    alt: "gallery",
  },
  gallery_7: {
    image: require("./home/gallery/gallery_7.jpg"),
    alt: "gallery",
  },
  galleryMobile_7: {
    image: require("./home/gallery/mb_gallery_7.jpg"),
    alt: "gallery",
  },
  galleryThumb_7: {
    image: require("./home/gallery/thumb_gallery_7.png"),
    alt: "gallery",
  },
  gallery_8: {
    image: require("./home/gallery/gallery_8.jpg"),
    alt: "gallery",
  },
  galleryMobile_8: {
    image: require("./home/gallery/mb_gallery_8.jpg"),
    alt: "gallery",
  },
  galleryThumb_8: {
    image: require("./home/gallery/thumb_gallery_8.png"),
    alt: "gallery",
  },
  gallery_9: {
    image: require("./home/gallery/gallery_9.jpg"),
    alt: "gallery",
  },
  galleryMobile_9: {
    image: require("./home/gallery/mb_gallery_9.jpg"),
    alt: "gallery",
  },
  galleryThumb_9: {
    image: require("./home/gallery/thumb_gallery_9.png"),
    alt: "gallery",
  },
  gallery_10: {
    image: require("./home/gallery/gallery_10.jpg"),
    alt: "gallery",
  },
  galleryMobile_10: {
    image: require("./home/gallery/mb_gallery_10.jpg"),
    alt: "gallery",
  },
  galleryThumb_10: {
    image: require("./home/gallery/thumb_gallery_10.png"),
    alt: "gallery",
  },
  gallery_11: {
    image: require("./home/gallery/gallery_11.jpg"),
    alt: "gallery",
  },
  galleryMobile_11: {
    image: require("./home/gallery/mb_gallery_11.jpg"),
    alt: "gallery",
  },
  galleryThumb_11: {
    image: require("./home/gallery/thumb_gallery_11.png"),
    alt: "gallery",
  },
  gallery_12: {
    image: require("./home/gallery/gallery_12.jpg"),
    alt: "gallery",
  },
  galleryMobile_12: {
    image: require("./home/gallery/mb_gallery_12.jpg"),
    alt: "gallery",
  },
  galleryThumb_12: {
    image: require("./home/gallery/thumb_gallery_12.png"),
    alt: "gallery",
  },
  gallery_13: {
    image: require("./home/gallery/gallery_13.jpg"),
    alt: "gallery",
  },
  galleryMobile_13: {
    image: require("./home/gallery/mb_gallery_13.jpg"),
    alt: "gallery",
  },
  galleryThumb_13: {
    image: require("./home/gallery/gallery_13.jpg"),
    alt: "gallery",
  },
  gallery_14: {
    image: require("./home/gallery/gallery_14.jpg"),
    alt: "gallery",
  },
  galleryMobile_14: {
    image: require("./home/gallery/mb_gallery_14.jpg"),
    alt: "gallery",
  },
  galleryThumb_14: {
    image: require("./home/gallery/thumb_gallery_14.png"),
    alt: "gallery",
  },
  gallery_15: {
    image: require("./home/gallery/gallery_15.jpg"),
    alt: "gallery",
  },
  galleryMobile_15: {
    image: require("./home/gallery/mb_gallery_15.jpg"),
    alt: "gallery",
  },
  galleryThumb_15: {
    image: require("./home/gallery/thumb_gallery_15.png"),
    alt: "gallery",
  },
  gallery_16: {
    image: require("./home/gallery/gallery_16.jpg"),
    alt: "gallery",
  },
  galleryMobile_16: {
    image: require("./home/gallery/mb_gallery_16.jpg"),
    alt: "gallery",
  },
  galleryThumb_16: {
    image: require("./home/gallery/thumb_gallery_16.png"),
    alt: "gallery",
  },
  gallery_17: {
    image: require("./home/gallery/gallery_17.jpg"),
    alt: "gallery",
  },
  galleryMobile_17: {
    image: require("./home/gallery/mb_gallery_17.jpg"),
    alt: "gallery",
  },
  galleryThumb_17: {
    image: require("./home/gallery/thumb_gallery_17.png"),
    alt: "gallery",
  },
  gallery_18: {
    image: require("./home/gallery/gallery_18.jpg"),
    alt: "gallery",
  },
  galleryMobile_18: {
    image: require("./home/gallery/mb_gallery_18.jpg"),
    alt: "gallery",
  },
  galleryThumb_18: {
    image: require("./home/gallery/thumb_gallery_18.png"),
    alt: "gallery",
  },
  gallery_19: {
    image: require("./home/gallery/gallery_19.jpg"),
    alt: "gallery",
  },
  galleryMobile_19: {
    image: require("./home/gallery/mb_gallery_19.jpg"),
    alt: "gallery",
  },
  galleryThumb_19: {
    image: require("./home/gallery/thumb_gallery_19.png"),
    alt: "gallery",
  },
  gallery_20: {
    image: require("./home/gallery/gallery_20.jpg"),
    alt: "gallery",
  },
  galleryMobile_20: {
    image: require("./home/gallery/mb_gallery_20.jpg"),
    alt: "gallery",
  },
  galleryThumb_20: {
    image: require("./home/gallery/thumb_gallery_20.png"),
    alt: "gallery",
  },
  gallery_21: {
    image: require("./home/gallery/gallery_21.jpg"),
    alt: "gallery",
  },
  galleryMobile_21: {
    image: require("./home/gallery/mb_gallery_21.jpg"),
    alt: "gallery",
  },
  galleryThumb_21: {
    image: require("./home/gallery/thumb_gallery_21.png"),
    alt: "gallery",
  },
  gallery_22: {
    image: require("./home/gallery/gallery_22.jpg"),
    alt: "gallery",
  },
  galleryMobile_22: {
    image: require("./home/gallery/mb_gallery_22.jpg"),
    alt: "gallery",
  },
  galleryThumb_22: {
    image: require("./home/gallery/thumb_gallery_22.png"),
    alt: "gallery",
  },
  gallery_23: {
    image: require("./home/gallery/gallery_23.jpg"),
    alt: "gallery",
  },
  galleryMobile_23: {
    image: require("./home/gallery/mb_gallery_23.jpg"),
    alt: "gallery",
  },
  galleryThumb_23: {
    image: require("./home/gallery/thumb_gallery_23.png"),
    alt: "gallery",
  },
  gallery_24: {
    image: require("./home/gallery/gallery_24.jpg"),
    alt: "gallery",
  },
  galleryMobile_24: {
    image: require("./home/gallery/mb_gallery_24.jpg"),
    alt: "gallery",
  },
  galleryThumb_24: {
    image: require("./home/gallery/thumb_gallery_24.png"),
    alt: "gallery",
  },
  gallery_25: {
    image: require("./home/gallery/gallery_25.jpg"),
    alt: "gallery",
  },
  galleryMobile_25: {
    image: require("./home/gallery/mb_gallery_25.jpg"),
    alt: "gallery",
  },
  galleryThumb_25: {
    image: require("./home/gallery/thumb_gallery_25.png"),
    alt: "gallery",
  },
  gallery_26: {
    image: require("./home/gallery/gallery_26.jpg"),
    alt: "gallery",
  },
  galleryMobile_26: {
    image: require("./home/gallery/mb_gallery_26.jpg"),
    alt: "gallery",
  },
  galleryThumb_26: {
    image: require("./home/gallery/thumb_gallery_26.png"),
    alt: "gallery",
  },
  gallery_27: {
    image: require("./home/gallery/gallery_27.png"),
    alt: "gallery",
  },
  galleryMobile_27: {
    image: require("./home/gallery/mb_gallery_27.png"),
    alt: "gallery",
  },
  galleryThumb_27: {
    image: require("./home/gallery/thumb_gallery_27.png"),
    alt: "gallery",
  },
  gallery_28: {
    image: require("./home/gallery/gallery_28.png"),
    alt: "gallery",
  },
  galleryMobile_28: {
    image: require("./home/gallery/mb_gallery_28.png"),
    alt: "gallery",
  },
  galleryThumb_28: {
    image: require("./home/gallery/thumb_gallery_28.png"),
    alt: "gallery",
  },
  gallery_29: {
    image: require("./home/gallery/gallery_29.png"),
    alt: "gallery",
  },
  galleryMobile_29: {
    image: require("./home/gallery/mb_gallery_29.png"),
    alt: "gallery",
  },
  galleryThumb_29: {
    image: require("./home/gallery/thumb_gallery_29.png"),
    alt: "gallery",
  },
  gallery_30: {
    image: require("./home/gallery/gallery_30.png"),
    alt: "gallery",
  },
  galleryMobile_30: {
    image: require("./home/gallery/mb_gallery_30.png"),
    alt: "gallery",
  },
  galleryThumb_30: {
    image: require("./home/gallery/thumb_gallery_30.png"),
    alt: "gallery",
  },
  gallery_31: {
    image: require("./home/gallery/gallery_31.png"),
    alt: "gallery",
  },
  galleryMobile_31: {
    image: require("./home/gallery/mb_gallery_31.png"),
    alt: "gallery",
  },
  galleryThumb_31: {
    image: require("./home/gallery/thumb_gallery_31.png"),
    alt: "gallery",
  },
  gallery_32: {
    image: require("./home/gallery/gallery_32.png"),
    alt: "gallery",
  },
  galleryMobile_32: {
    image: require("./home/gallery/mb_gallery_32.png"),
    alt: "gallery",
  },
  galleryThumb_32: {
    image: require("./home/gallery/thumb_gallery_32.png"),
    alt: "gallery",
  },
  gallery_33: {
    image: require("./home/gallery/gallery_33.png"),
    alt: "gallery",
  },
  galleryMobile_33: {
    image: require("./home/gallery/mb_gallery_33.png"),
    alt: "gallery",
  },
  galleryThumb_33: {
    image: require("./home/gallery/thumb_gallery_33.png"),
    alt: "gallery",
  },
  gallery_34: {
    image: require("./home/gallery/gallery_34.png"),
    alt: "gallery",
  },
  galleryMobile_34: {
    image: require("./home/gallery/mb_gallery_34.png"),
    alt: "gallery",
  },
  galleryThumb_34: {
    image: require("./home/gallery/thumb_gallery_34.png"),
    alt: "gallery",
  },
  gallery_35: {
    image: require("./home/gallery/gallery_35.png"),
    alt: "gallery",
  },
  galleryMobile_35: {
    image: require("./home/gallery/mb_gallery_35.png"),
    alt: "gallery",
  },
  galleryThumb_35: {
    image: require("./home/gallery/thumb_gallery_35.png"),
    alt: "gallery",
  },
  gallery_36: {
    image: require("./home/gallery/gallery_36.png"),
    alt: "gallery",
  },
  galleryMobile_36: {
    image: require("./home/gallery/mb_gallery_36.png"),
    alt: "gallery",
  },
  galleryThumb_36: {
    image: require("./home/gallery/thumb_gallery_36.png"),
    alt: "gallery",
  },
  gallery_37: {
    image: require("./home/gallery/gallery_37.png"),
    alt: "gallery",
  },
  galleryMobile_37: {
    image: require("./home/gallery/mb_gallery_37.png"),
    alt: "gallery",
  },
  galleryThumb_37: {
    image: require("./home/gallery/thumb_gallery_37.png"),
    alt: "gallery",
  },
  gallery_38: {
    image: require("./home/gallery/gallery_38.png"),
    alt: "gallery",
  },
  galleryMobile_38: {
    image: require("./home/gallery/mb_gallery_38.png"),
    alt: "gallery",
  },
  galleryThumb_38: {
    image: require("./home/gallery/thumb_gallery_38.png"),
    alt: "gallery",
  },
  gallery_39: {
    image: require("./home/gallery/gallery_39.png"),
    alt: "gallery",
  },
  galleryMobile_39: {
    image: require("./home/gallery/mb_gallery_39.png"),
    alt: "gallery",
  },
  galleryThumb_39: {
    image: require("./home/gallery/thumb_gallery_39.png"),
    alt: "gallery",
  },
  gallery_40: {
    image: require("./home/gallery/gallery_40.png"),
    alt: "gallery",
  },
  galleryMobile_40: {
    image: require("./home/gallery/mb_gallery_40.png"),
    alt: "gallery",
  },
  galleryThumb_40: {
    image: require("./home/gallery/thumb_gallery_40.png"),
    alt: "gallery",
  },
  gallery_41: {
    image: require("./home/gallery/gallery_41.png"),
    alt: "gallery",
  },
  galleryMobile_41: {
    image: require("./home/gallery/mb_gallery_41.png"),
    alt: "gallery",
  },
  galleryThumb_41: {
    image: require("./home/gallery/thumb_gallery_41.png"),
    alt: "gallery",
  },
  gallery_42: {
    image: require("./home/gallery/gallery_42.png"),
    alt: "gallery",
  },
  galleryMobile_42: {
    image: require("./home/gallery/mb_gallery_42.png"),
    alt: "gallery",
  },
  galleryThumb_42: {
    image: require("./home/gallery/thumb_gallery_42.png"),
    alt: "gallery",
  },
  gallery_43: {
    image: require("./home/gallery/gallery_43.png"),
    alt: "gallery",
  },
  galleryMobile_43: {
    image: require("./home/gallery/mb_gallery_43.png"),
    alt: "gallery",
  },
  galleryThumb_43: {
    image: require("./home/gallery/thumb_gallery_43.png"),
    alt: "gallery",
  },
  gallery_44: {
    image: require("./home/gallery/gallery_44.png"),
    alt: "gallery",
  },
  galleryMobile_44: {
    image: require("./home/gallery/mb_gallery_44.png"),
    alt: "gallery",
  },
  galleryThumb_44: {
    image: require("./home/gallery/thumb_gallery_44.png"),
    alt: "gallery",
  },
  gallery_45: {
    image: require("./home/gallery/gallery_45.png"),
    alt: "gallery",
  },
  galleryMobile_45: {
    image: require("./home/gallery/mb_gallery_45.png"),
    alt: "gallery",
  },
  galleryThumb_45: {
    image: require("./home/gallery/thumb_gallery_45.png"),
    alt: "gallery",
  },

  sponsor01: {
    image: require("./home/sponsors/ibis_1.png"),
    alt: "ibis",
  },
  sponsor02: {
    image: require("./home/sponsors/Nuvama_1.png"),
    alt: "Nuvama",
  },
  sponsor03: {
    image: require("./home/sponsors/HELL_1.png"),
    alt: "HELL",
  },
  sponsor04: {
    image: require("./home/sponsors/YES_BANK_1.png"),
    alt: "YES BANK",
  },
  sponsor05: {
    image: require("./home/sponsors/Tyka_1.png"),
    alt: "Tyka",
  },
  sponsor06: {
    image: require("./home/sponsors/route_energy_1.png"),
    alt: "route energy",
  },

  // Fixture images
  teamLogo01: {
    image: require("./fixture/mumbai_logo_1.png"),
    alt: "majhi mumbai",
  },
  teamLogo02: {
    image: require("./fixture/singam_logo_1.png"),
    alt: "chennai singams",
  },
  teamLogo03: {
    image: require("./fixture/Tigers.png"),
    alt: "Tigers",
  },
  teamLogo04: {
    image: require("./fixture/Falcon_Risers.png"),
    alt: "Falcon Risers",
  },
  teamLogo05: {
    image: require("./fixture/Bangalore.png"),
    alt: "Bangalore",
  },
  teamLogo06: {
    image: require("./fixture/srinagar.png"),
    alt: "srinagar",
  },

  // squad images
  squad01: {
    image: require("./squad/ANIKET_SANAP.png"),
    alt: "ANIKET SANAP",
  },
  squad02: {
    image: require("./squad/BABLU_PATIL.png"),
    alt: "BABLU PATIL",
  },
  squad03: {
    image: require("./squad/DILIP_BINJWA.png"),
    alt: "DILIP BINJWA",
  },
  squad04: {
    image: require("./squad/FARHAT_AHMED.png"),
    alt: "FARHAT AHMED",
  },
  squad05: {
    image: require("./squad/FARMAN_KHAN.png"),
    alt: "FARMAN KHAN",
  },
  squad06: {
    image: require("./squad/HARISH_PARMAR.png"),
    alt: "HARISH PARMAR",
  },
  squad07: {
    image: require("./squad/KETAN_MHATRE.png"),
    alt: "KETAN MHATRE",
  },
  squad08: {
    image: require("./squad/PANKAJ_PATEL.png"),
    alt: "PANKAJ PATEL",
  },
  squad09: {
    image: require("./squad/R THAVITH_KUMAR.png"),
    alt: "R THAVITH KUMAR",
  },
  squad10: {
    image: require("./squad/RAJDEEP_JADEJA.png"),
    alt: "RAJDEEP JADEJA",
  },
  squad11: {
    image: require("./squad/SAGAR_ALI.png"),
    alt: "SAGAR ALI",
  },
  squad12: {
    image: require("./squad/SANJAY_KANOJJIYA.png"),
    alt: "SANJAY KANOJJIYA",
  },
  squad13: {
    image: require("./squad/SUMEET_DHEKALE.png"),
    alt: "SUMEET DHEKALE",
  },
  squad14: {
    image: require("./squad/V_VIGNESH.png"),
    alt: "V VIGNESH",
  },
  squad15: {
    image: require("./squad/VEDANT_MAYEKAR.png"),
    alt: "VEDANT MAYEKAR",
  },
  squad16: {
    image: require("./squad/VISHWANATH_JADHAV.png"),
    alt: "VISHWANATH JADHAV",
  },

  // stats images
  stats01: {
    image: require("./stats/KETAN_MHATRE.png"),
    alt: "KETAN MHATRE",
  },
  stats02: {
    image: require("./stats/BABLU_PATIL.png"),
    alt: "BABLU PATIL",
  },
  stats03: {
    image: require("./stats/SAGAR_ALI.png"),
    alt: "Sagar ali",
  },
  stats04: {
    image: require("./stats/Sanjay_kanojjiya.png"),
    alt: "Sanjay kanojjiya",
  },
  stats05: {
    image: require("./stats/Dilip.png"),
    alt: "Dilip",
  },
  stats06: {
    image: require("./stats/Vishwanath.png"),
    alt: "Vishwanath",
  },
  stats07: {
    image: require("./stats/R_Thavith.png"),
    alt: "R. Thavith",
  },
  stats08: {
    image: require("./stats/Farman.png"),
    alt: "Farman",
  },
  stats09: {
    image: require("./stats/Rajdeep.png"),
    alt: "Rajdeep",
  },
  stats10: {
    image: require("./stats/V_VIGNESH.png"),
    alt: "V VIGNESH",
  },

  // Media images
  media00: {
    image: require("./media/TImes_Now.jpg"),
    alt: "Media",
  },
  mbMedia00: {
    image: require("./media/mb_TImes_Now.jpg"),
    alt: "Media",
  },
  media01: {
    image: require("./media/Midday.jpg"),
    alt: "Media",
  },
  media02: {
    image: require("./media/Sports_Mint_Media.jpg"),
    alt: "Media",
  },
  media03: {
    image: require("./media/Online_News_9.jpg"),
    alt: "Media",
  },
  media04: {
    image: require("./media/Business_Micro.jpg"),
    alt: "Media",
  },
  media05: {
    image: require("./media/Indian_Television.jpg"),
    alt: "Media",
  },
  media06: {
    image: require("./media/CNBC_TV_Pre_Season.jpg"),
    alt: "Media",
  },
  media07: {
    image: require("./media/Media_Express.jpg"),
    alt: "Media",
  },
  media08: {
    image: require("./media/City_Air_News.jpg"),
    alt: "Media",
  },
  media09: {
    image: require("./media/Business_News_this_Week.jpg"),
    alt: "Media",
  },
  media10: {
    image: require("./media/CNBC_TV_In_Season.jpg"),
    alt: "Media",
  },
  media11: {
    image: require("./media/Gunshot_Magazine.jpg"),
    alt: "Media",
  },
  media12: {
    image: require("./media/Hellokotpad.jpg"),
    alt: "Media",
  },
  media13: {
    image: require("./media/United_News_of_India.jpg"),
    alt: "Media",
  },
  media14: {
    image: require("./media/Passionate_Marketing.jpg"),
    alt: "Media",
  },
  media15: {
    image: require("./media/Saamana.jpg"),
    alt: "Media",
  },
  media16: {
    image: require("./media/APN_News.jpg"),
    alt: "Media",
  },
  media17: {
    image: require("./media/Khel_Today.jpg"),
    alt: "Media",
  },
  media18: {
    image: require("./media/Republic_World.jpg"),
    alt: "Media",
  },
  media19: {
    image: require("./media/BJ_Sports.jpg"),
    alt: "Media",
  },

  // Singamstv images
  singamstv00: {
    image: require("./singams-tv/Chennai_Singams_vs_Tiigers_of_Kolkata_Match_1.jpg"),
    alt: "Media",
  },
  mbSingamstv00: {
    image: require("./singams-tv/mb_Chennai_Singams_vs_Tiigers_of_Kolkata_Match_1.jpg"),
    alt: "Media",
  },

  singamstv01: {
    image: require("./singams-tv/Chennai_Singams_vs_KVN_Bangalore_Strikers_Match_2.jpg"),
    alt: "Media",
  },
  singamstv02: {
    image: require("./singams-tv/Majhi_Mumbai_vs_Chennai_Singams_Match_3.jpg"),
    alt: "Media",
  },
  singamstv03: {
    image: require("./singams-tv/Srinagar_ke_Veer_vs_Chennai_Singams_Match_4.jpg"),
    alt: "Media",
  },
  singamstv04: {
    image: require("./singams-tv/Falcon_Risers_Hyderabad_vs_Chennai_Singams_Match_5.jpg"),
    alt: "Media",
  },
  singamstv05: {
    image: require("./singams-tv/Majhi_Mumbai_vs_Chennai_Singams_Semi_final_1.jpg"),
    alt: "Media",
  },
  singamstv06: {
    image: require("./singams-tv/Suriya_at_ISPL_opening_ceremony.jpg"),
    alt: "Media",
  },
  singamstv07: {
    image: require("./singams-tv/Suriya_at_Chennai_Singams_vs_Tiigers_of_Kolkata_Match_1.jpg"),
    alt: "Media",
  },
  singamstv08: {
    image: require("./singams-tv/V_Vignesh_Tape_Ball_over_vs_Majhi_Mumbai_Semi_Final_1.jpg"),
    alt: "Media",
  },
  singamstv09: {
    image: require("./singams-tv/Vishwanath_Jadhav_50_50_Challenge_Over_vs_Srinagar_ke_Veer_Match_4.jpg"),
    alt: "Media",
  },
  singamstv10: {
    image: require("./singams-tv/R_Thavith_Kumar_4_wicket_haul_vs_Tiigers_of_Kolkata_Match_1.jpg"),
    alt: "Media",
  },
  singamstv11: {
    image: require("./singams-tv/Holi_Celebration.jpg"),
    alt: "Media",
  },
  singamstv12: {
    image: require("./singams-tv/Describe_your_ISPL_journey_in_one_word.jpg"),
    alt: "Media",
  },
  singamstv13: {
    image: require("./singams-tv/Suriya_Promo_(Hindi).jpg"),
    alt: "Media",
  },
  singamstv14: {
    image: require("./singams-tv/Suriya_Promo_(Tamil).jpg"),
    alt: "Media",
  },
  singamstv15: {
    image: require("./singams-tv/Suriya_Opening_Ceremony.jpg"),
    alt: "Media",
  },
  singamstv16: {
    image: require("./singams-tv/Season_Highlight_Video.jpg"),
    alt: "Media",
  },
  singamstv17: {
    image: require("./singams-tv/Player_Video_Bablu_Patil.jpg"),
    alt: "Media",
  },
  singamstv18: {
    image: require("./singams-tv/Player_Video_Ketan_Mhatre.jpg"),
    alt: "Media",
  },
  singamstv19: {
    image: require("./singams-tv/Player_Video_Sumeet_Dhekale.jpg"),
    alt: "Media",
  },
  singamstv20: {
    image: require("./singams-tv/Ibis_Ad.jpg"),
    alt: "Media",
  },
  singamstv21: {
    image: require("./singams-tv/Players_break_day_with_ibis.jpg"),
    alt: "Media",
  },
  singamstv22: {
    image: require("./singams-tv/Training_Video.jpg"),
    alt: "Media",
  },
  singamstv23: {
    image: require("./singams-tv/Gym_Reel.jpg"),
    alt: "Media",
  },
};
