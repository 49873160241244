import React from "react";
import "./contactus.scss";
import { useWindowSize } from "react-use";
import { Link } from "react-router-dom";
import { homepageURL } from "../helpers/constant-words";

const ContactUs = () => {
  const { width } = useWindowSize();

  return (
    <>
      {width <= 767 && (
        <section
          className="contact_cta"
          // onClick={() => handleScrollDiv("contact", -100)}
        >
          <div className="btn_wrapper">
            <Link to={`${homepageURL}#contact`}>
              <button className="submit_btn">Contact Us</button>
            </Link>
          </div>
        </section>
      )}
    </>
  );
};

export default ContactUs;
