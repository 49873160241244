import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Button } from "react-scroll";

const HeaderNavItem = ({
  arrIndex,
  navData,
  handleHamburgerClick,
  setActiveIndex,
  activeIndex,
}) => {
  const hash = window.location.hash;
  const handleClick = () => {
    handleHamburgerClick(arrIndex);
    setActiveIndex(arrIndex);
  };

  return (
    <>
      {navData.type === "button" ? (
        <Button
          style={{
            border: "none",
            backgroundColor: "transparent",
          }}
          to={navData.scrollId}
          smooth={true}
          spy={true}
          offset={0}
          duration={1}
          onClick={() => handleClick(navData.id + 1)}
        >
          <div className="btn_wrapper">
            <Link
              to={navData.mainLink}
              className={`nav_link ${
                hash === "#" + navData.scrollId ? "nav_btn_active" : "no_active"
              }`}
            >
              <button className="submit_btn">{navData.mainTitle}</button>
            </Link>
          </div>
        </Button>
      ) : (
        <li className="nav_item">
          {navData.scrollId ? (
            <Button
              style={{
                border: "none",
                backgroundColor: "transparent",
              }}
              to={navData.scrollId}
              smooth={true}
              spy={true}
              offset={-60}
              duration={1}
              className="nav_btn"
              onClick={() => handleClick(navData.id + 1)}
            >
              <Link
                to={`${navData.mainLink}#${navData.scrollId}`}
                className={`nav_link ${
                  arrIndex === activeIndex || hash === "#" + navData.scrollId
                    ? "nav_btn_active"
                    : "no_active"
                }`}
              >
                {navData.mainTitle}
              </Link>
            </Button>
          ) : (
            <NavLink
              to={navData.mainLink}
              className={({ isActive }) => {
                return `${isActive ? "nav_link active" : "nav_link"}`;
              }}
            >
              {navData.mainTitle}
            </NavLink>
          )}
        </li>
      )}
    </>
  );
};

export default HeaderNavItem;
