import React from "react";
import "./banner.scss";
import { Link } from "react-router-dom";
import { homepageURL } from "../helpers/constant-words";
import { homeIcon } from "../../images";

const Banner = ({ link, linkText, text, disable, bannerImg }) => {
  return (
    <>
      <section className="com_banner_sec1">
        <div className="banner_container" data-aos="fade">
          <img
            src={bannerImg}
            alt="fixture banner"
            className="banner_img"
            loading="lazy"
          />
          <div className="my_container">
            <div className="breadcrumb">
              <div className="custom_breadcrumb">
                <Link to={homepageURL} className="blink_1">
                  <img
                    width={20}
                    height={18}
                    src={homeIcon}
                    alt="home"
                    className="home_icon"
                    loading="lazy"
                  />
                </Link>{" "}
                <Link
                  className={`home_url ${disable && "disable_link"}`}
                  to={link}
                >
                  {" > "} {linkText}
                </Link>{" "}
              </div>
            </div>
          </div>
          {text && (
            <div className="banner_content_container">
              <h1 className="heading">{text}</h1>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Banner;
