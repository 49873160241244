import { images } from "../images";

export const ongoinData = [
  {
    id: 1,
    title: "SEMI-FINAL",
    date: "14 MARCH, 2024",
    time: "05:00 PM",
    team01Img: images.teamLogo01.image,
    team01Alt: images.teamLogo01.alt,
    team02Img: images.teamLogo02.image,
    team02Alt: images.teamLogo02.alt,
    imgWidth1: "mumbai_logo",
    imgWidth2: "singams_logo",
  },
  // {
  //   id: 2,
  //   title: "FINAL",
  //   date: "10 MARCH, 2024",
  //   time: "05:00 PM",
  //   team01Img: images.teamLogo01.image,
  //   team01Alt: images.teamLogo01.alt,
  //   team02Img: images.teamLogo02.image,
  //   team02Alt: images.teamLogo02.alt,
  //   imgWidth1: "mumbai_logo",
  //   imgWidth2: "singams_logo",
  // },
];

export const upcomingData = [
  {
    id: 1,
    date: "07 MARCH, 2024",
    time: "05:00 PM",
    team01Img: images.teamLogo02.image,
    team01Alt: images.teamLogo02.alt,
    team02Img: images.teamLogo03.image,
    team02Alt: images.teamLogo03.alt,
    imgWidth1: "singams_logo",
    imgWidth2: "tigers_logo",
    // vsImg: "vs_img1",
    containerClass: "logo_wrapper1",
  },
  {
    id: 2,
    date: "08 MARCH, 2024",
    time: "05:00 PM",
    team01Img: images.teamLogo02.image,
    team01Alt: images.teamLogo02.alt,
    team02Img: images.teamLogo05.image,
    team02Alt: images.teamLogo05.alt,
    imgWidth1: "singams_logo",
    imgWidth2: "banglore_logo",
    vsImg: "vs_img2",
    containerClass: "logo_wrapper2",
  },
  {
    id: 3,
    date: "10 MARCH, 2024",
    time: "05:00 PM",
    team01Img: images.teamLogo01.image,
    team01Alt: images.teamLogo01.alt,
    team02Img: images.teamLogo02.image,
    team02Alt: images.teamLogo02.alt,
    imgWidth1: "mumbai_logo",
    imgWidth2: "singams_logo",
    vsImg: "vs_img3",
    containerClass: "logo_wrapper3",
  },
  {
    id: 4,
    date: "12 MARCH, 2024",
    time: "05:00 PM",
    team01Img: images.teamLogo06.image,
    team01Alt: images.teamLogo06.alt,
    team02Img: images.teamLogo02.image,
    team02Alt: images.teamLogo02.alt,
    imgWidth1: "srinagar_logo",
    imgWidth2: "singams_logo",
    vsImg: "vs_img4",
    containerClass: "logo_wrapper4",
  },
  {
    id: 5,
    date: "13 MARCH, 2024",
    time: "07:30 PM",
    team01Img: images.teamLogo04.image,
    team01Alt: images.teamLogo04.alt,
    team02Img: images.teamLogo02.image,
    team02Alt: images.teamLogo02.alt,
    imgWidth1: "falcon_logo",
    imgWidth2: "singams_logo",
    vsImg: "vs_img5",
    containerClass: "logo_wrapper5",
  },
];
