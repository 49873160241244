import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  fixtureURL,
  homepageURL,
  mediaURL,
  scoreURL,
  singamsTvURL,
  squadURL,
  statsURL,
} from "./components/helpers/constant-words";
import Homepage from "./pages/Homepage/Homepage";
import Fixture from "./pages/Fixture/Fixture";
import Score from "./pages/Score/Score";
import Squad from "./pages/Squad/Squad";
import Stats from "./pages/Stats/Stats";
import Media from "./pages/Media/Media";
import SingamsTv from "./pages/SingamsTv/SingamsTv";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route path={homepageURL} element={<Homepage />} />
        <Route path={fixtureURL} element={<Fixture />} />
        <Route path={scoreURL} element={<Score />} />
        <Route path={squadURL} element={<Squad />} />
        <Route path={statsURL} element={<Stats />} />
        <Route path={mediaURL} element={<Media />} />
        <Route path={singamsTvURL} element={<SingamsTv />} />
        {/* <Route exact path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} /> */}
      </Routes>
    </>
  );
};
export default Routing;
