import React, { useEffect, useState } from "react";
import "./homepage.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Pagination,
  FreeMode,
  Navigation,
  Thumbs,
  Autoplay,
} from "swiper/modules";
import {
  ball,
  bannerLogo1,
  bannerLogo2,
  bat,
  chennaiLogo,
  galleryFade,
  grass,
  leagueImg,
  leftArrow,
  mbLeagueImg,
  mbUpcomingBack,
  mbWhiteBack,
  plusSign,
  rightArrow,
  sony10,
  sony6,
  sonyLiv,
  upcomingBack,
  whiteBack,
  whiteCircle,
  yellowBack,
} from "../../images";
import { useWindowSize } from "react-use";
import {
  bannerData,
  founderData,
  galleryImage,
  jerseyLogos,
} from "../../data/homeData";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import Joi from "joi";
import config from "../../config/config";
import http from "../../helpers/http";
// import { useNavigate } from "react-router-dom";
import WhatsApp from "../../components/whatsApp/whatsApp";

const Homepage = () => {
  const { width } = useWindowSize();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [values, setValues] = useState({
    name: "",

    email: "",

    phone: "",
    company: "",
    message: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [isThankyouVisible, setIsThankyouVisible] = useState(false);

  // const [consent, setConsent] = useState(false);

  let base_url = config.api_url + "/forms/contact";

  // const navigate = useNavigate();

  const schema = Joi.object({
    name: Joi.string().trim().required().label("Name"),
    company: Joi.string().trim().required().label("Company"),

    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email Id"),
    // phone: Joi.number().required().label("Contact No."),
    phone: Joi.string()
      .length(10)
      .pattern(/^[0-9]+$/)
      .messages({ "string.pattern.base": `Contact No. must have 10 digits.` })
      .required()
      .label("Contact No."),

    // message: Joi.string().trim().label("Message"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);

    console.log({ errors });
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    // console.log(...formData);
    // return;

    const { data } = await http.post(base_url, values);

    if (data) {
      setValues({
        name: "",

        email: "",

        phone: "",
        company: "",
        message: "",
      });

      setIsThankyouVisible(true);
      // navigate("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.checked,
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };
  // const pagination = {
  //   clickable: true,
  //   // el: ".swiper-pagination",
  //   renderBullet: function (index, className) {
  //     return `<span class="${className}">
  //     <img src="${bulletsImage[index]}" class="bullet_img" />
  //     </span>`;
  //   },
  // };

  const bannerList = bannerData.map((item, i) => (
    <SwiperSlide key={i}>
      {item.type === "image" ? (
        <div className="img_wrapper">
          <img
            src={width > 767 ? item.img : item.mbImg}
            alt={item.alt}
            className="banner_img"
            loading="lazy"
          />
          <img
            src={bannerLogo1}
            alt="chennai singams"
            className="banner_logo1"
          />
          <img
            src={bannerLogo2}
            alt="chennai singams"
            className="banner_logo2"
          />
        </div>
      ) : (
        <video className="video_wrapper" autoPlay muted loop playsInline>
          <source
            className="video_source"
            src={width > 767 ? item.video : item.mbVideo}
            type="video/mp4"
          />
        </video>
      )}
    </SwiperSlide>
  ));

  const founderList = founderData.map((item, i) => (
    <div className="col-md-6 col-lg-4 founder_col" data-aos="fade-up" key={i}>
      <div className="founder_details_wrapper">
        <img
          src={width > 767 ? whiteBack : mbWhiteBack}
          alt=""
          className="content_back"
        />
        <div className="content_wrapper">
          <div className="details_wrapper order-md-2">
            <p className="description">{item.desc}</p>
            <h2 className="name">{item.name}</h2>
            <p className="desg">{item.desg}</p>
          </div>
          <div className="img_wrapper order-md-1">
            <img src={item.img} alt={item.alt} className="founder_img" />
          </div>
        </div>
      </div>
    </div>
  ));

  // const paginationSliderList = galleryImage.map((item, i) => (
  //   <SwiperSlide key={i}>
  //       <img src={item.img} alt={item.alt} className="main_img" />
  //   </SwiperSlide>
  // ));

  const galleryList = galleryImage.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="img_wrapper">
        <img
          src={width > 767 ? item.img : item.mbImg}
          alt={item.alt}
          className="main_img"
        />
      </div>
    </SwiperSlide>
  ));

  const galleryThumb = galleryImage.map((item, i) => (
    <SwiperSlide key={i}>
      <img src={item.thumbImg} alt={item.alt} className="thumb_img" />
    </SwiperSlide>
  ));

  const jerseyLogoList = jerseyLogos.map((item, i) => (
    <SwiperSlide key={i}>
      <img src={item.img} alt={item.alt} className="company_logo" />
    </SwiperSlide>
  ));

  const handleScrollDiv = (scrollid, offset) => {
    const id = scrollid;
    const yOffset = offset;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetId = hash.substring(1);
      const targetSection = document.getElementById(targetId);
      if (targetSection) {
        setTimeout(() => {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }, 0);
      }
    }
  }, []);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <>
      <Header />
      <main className="home_main">
        <section className="home_sec1">
          <div className="banner_wrapper">
            <Swiper
              slidesPerView={1}
              // spaceBetween={50}
              loop={true}
              modules={[Pagination, Autoplay]}
              autoplay={{
                delay: 15000,
                disableOnInteraction: false,
              }}
              pagination={{
                el: ".swiper-pagination",
                clickable: true,
              }}
              className="banner_slider"
            >
              {bannerList}
            </Swiper>
            <div className="swiper-pagination"></div>
          </div>
        </section>

        <section className="home_sec2" id="about">
          <img src={yellowBack} alt="background" className="back_img" />
          <div className="big_title_wrapper" data-aos="fade-up">
            <h2 className="big_title">ABOUT THE</h2>
            <h2 className="big_title big_title_1">TEAM</h2>
          </div>
          <div className="my_container">
            <div className="details_container" data-aos="fade-up">
              <img
                src={width > 767 ? whiteBack : mbWhiteBack}
                alt="white background"
                className="white_back"
              />
              <img
                src={chennaiLogo}
                alt="white background"
                className="chennai_logo"
              />
              <img src={ball} alt="ball" className="ball_img" />
              <div className="content_wrapper">
                <div className="title_wrapper">
                  <h2 className="title">
                    MEET THE <br /> CHENNAI SINGAMS
                  </h2>
                  <img src={grass} alt="ball" className="grass_img" />
                </div>
                <p className="desc">
                  The absolute lions of the Indian Street Premier League (ISPL)!{" "}
                  <br />
                  We're not just a cricket team; we're the living, breathing
                  spirit of Chennai on the pitch. Picture this: fearless,
                  determined, and ready to unleash the roar that echoes our
                  city's vibrant energy.
                </p>
                <p className="desc">
                  Our swagger isn't just in our game; it's in the way we embrace
                  the challenge, embodying the true essence of street cricket.
                  The Singams are more than a team; we're a force - a dynamic
                  blend of raw talent and that unmistakable Chennai attitude.
                </p>
                <p className="desc">
                  When we step onto the field, it's not just about playing
                  cricket; it's about owning the game with style and passion.
                  The unwavering determination in every swing, the fearless
                  approach to every ball - that's the Singams' way.
                </p>
                <p className="desc">
                  So, gear up for a rollercoaster of electrifying matches, epic
                  showdowns, and the unmistakable roar of the Chennai Singams -
                  because when we play, we play with heart and soul!
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="home_sec3" id="tournaments">
          <img
            src={width > 767 ? upcomingBack : mbUpcomingBack}
            alt="upcoming"
            className="back_img upcoming_back"
          />
          <img src={whiteCircle} alt="circle" className="white_circle" />
          <img src={plusSign} alt="circle" className="element" />
          <div className="my_container">
            <div className="upcoming_wrapper" data-aos="fade-up">
              <div className="title_wrapper">
                <h2 className="title">UPCOMING TOURNAMENTS</h2>
                <img src={grass} alt="ball" className="grass_img" />
              </div>

              <div className="channel_wrapper" data-aos="fade-up">
                <div className="league_wrapper">
                  <img
                    src={width > 767 ? leagueImg : mbLeagueImg}
                    alt="league"
                    className="league_img"
                  />
                </div>
                <div className="watch_wrapper">
                  {width > 767 ? (
                    <div className="big_title_wrapper" data-aos="fade-up">
                      <h2 className="big_title">TOURNAMENTS</h2>
                    </div>
                  ) : null}
                  <h4 className="title">WATCH ON</h4>
                  <div className="channel_name">
                    <a href="javacript:void(0)" className="channel">
                      <img
                        src={sonyLiv}
                        alt="sonyl liv"
                        className="channel_img"
                      />
                    </a>
                    <a href="javacript:void(0)" className="channel">
                      <img
                        src={sony6}
                        alt="sonyl 6"
                        className="channel_img_1"
                      />
                    </a>
                    <a href="javacript:void(0)" className="channel">
                      <img
                        src={sony10}
                        alt="sonyl 10"
                        className="channel_img_1"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="home_sec4" id="owners">
          <img src={yellowBack} alt="upcoming" className="back_img" />
          <div className="founder_title_wrapper" data-aos="fade-up">
            <h2 className="big_title">OWNERS</h2>
          </div>
          <div className="fouder_container" data-aos="fade-up">
            <div className="title_wrapper">
              <h2 className="title">OUR OWNERS</h2>
              <img src={grass} alt="ball" className="grass_img" />
            </div>
            <div className="row founder_row">{founderList}</div>
          </div>

          <div className="gallery_title" data-aos="fade-up">
            <div className="gallery_title_wrapper">
              <h2 className="big_title">GALLERY</h2>
            </div>
            <div className="my_container">
              <div className="title_wrapper">
                <h2 className="title">GALLERY</h2>
                <img src={grass} alt="ball" className="grass_img" />
              </div>
            </div>
          </div>
        </section>

        <section className="home_sec5">
          <div className="gallery_container" data-aos="fade-up">
            {/* <img src={galleryFade} alt="fade" className="fade_bg" /> */}
            <Swiper
              loop={true}
              slidesPerView={1}
              spaceBetween={0}
              // navigation={true}
              centeredSlides={true}
              // slideToClickedSlide={true}
              navigation={{
                nextEl: ".gallery_next",
                prevEl: ".gallery_prev",
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs, Autoplay]}
              // centeredSlides={true}
              // controller-control="thumb_swiper"
              className="main_swiper"
            >
              {galleryList}
              <div className="arrows_wrapper">
                <img src={leftArrow} alt="" className="gallery_prev" />
                <img src={rightArrow} alt="" className="gallery_next" />
              </div>
              <img src={galleryFade} alt="fade" className="fade_bg" />
            </Swiper>

            <Swiper
              onSwiper={setThumbsSwiper}
              loop={true}
              spaceBetween={22}
              slidesPerView={5}
              // slidesPerView="auto"
              // freeMode={true}
              slideToClickedSlide={true}
              // watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              centeredSlides={true}
              // controller-control="main_swiper"
              className="thumb_swiper"
              // autoplay={{
              //   delay: 3000,
              //   disableOnInteraction: false,
              // }}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 13.66,
                },
                500: {
                  slidesPerView: 3,
                  spaceBetween: 13.66,
                },
                650: {
                  slidesPerView: 3.5,
                  spaceBetween: 13.66,
                },
                768: {
                  slidesPerView: 5,
                },
                1024: {
                  slidesPerView: 5,
                },
                1280: {
                  slidesPerView: 5,
                },
              }}
            >
              {galleryThumb}
            </Swiper>
          </div>
        </section>

        {/* <section className="home_sec5">
          <div className="gallery_container">
            <Swiper
              pagination={pagination}
              modules={[Navigation, Pagination]}
              loop={true}
              navigation={{
                nextEl: ".gallery_next",
                prevEl: ".gallery_prev",
              }}
              className="gallery_slider"
            >
              {paginationSliderList}
              <div className="arrows_wrapper">
                <img src={leftArrow} alt="" className="gallery_prev" />
                <img src={rightArrow} alt="" className="gallery_next" />
              </div>
              //  <div className="swiper-pagination"></div> 
            </Swiper>
          </div>
        </section> */}

        <section className="home_sec6" id="contact">
          <img src={yellowBack} alt="upcoming" className="back_img" />
          <div className="contact_title_wrapper" data-aos="fade-up">
            <h2 className="big_title">CONTACT</h2>
          </div>
          <div className="my_container">
            <div className="title_wrapper" data-aos="fade-up">
              <h2 className="title">CONTACT US</h2>
              <img src={grass} alt="ball" className="grass_img" />
            </div>

            <form onSubmit={handleSubmit}>
              <div className="row contact_row" data-aos="fade-up">
                <div className="col-md-12 col-lg-6 contact_col">
                  <div className="input_wrapper">
                    <input
                      type="text"
                      className="form_input"
                      placeholder="Name*"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <p className="error">{formError.name}</p>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 contact_col">
                  <div className="input_wrapper">
                    <input
                      type="email"
                      className="form_input"
                      placeholder="Email Id*"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <p className="error">{formError.email}</p>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 contact_col">
                  <div className="input_wrapper">
                    <input
                      type="tel"
                      className="form_input"
                      placeholder="Contact No.*"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                    />
                    <p className="error">{formError.phone}</p>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 contact_col">
                  <div className="input_wrapper">
                    <input
                      type="text"
                      className="form_input"
                      placeholder="Company*"
                      name="company"
                      value={values.company}
                      onChange={handleChange}
                    />
                    <p className="error">{formError.company}</p>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 contact_col">
                  <div className="input_wrapper">
                    <textarea
                      className="form_input form_textarea"
                      placeholder="Message"
                      name="message"
                      value={values.message}
                      onChange={handleChange}
                    ></textarea>
                    <p className="error">{formError.message}</p>
                    {width > 767 ? (
                      <img src={bat} alt="bat" className="bat_img" />
                    ) : null}
                  </div>
                </div>
                {/* <div className="col-md-6 contact_col">
                <TypeSelect
                  id="email"
                  errorId="email_error"
                  mapFun={selectData}
                />
              </div> */}
              </div>
              <div className="btn_wrapper">
                <button className="submit_btn">
                  {" "}
                  {loading ? "Loading.." : "Contact Us"}
                </button>
              </div>
              <p
                className="thank_msg"
                style={{ display: isThankyouVisible ? "block" : "none" }}
              >
                Thank you for your inquiry. Our team will reach out to you in
                due course.
              </p>
            </form>
          </div>
        </section>

        <section className="home_sec7">
          <img src={yellowBack} alt="upcoming" className="back_img" />
          <div className="s_title_wrapper" data-aos="fade-up">
            <h2 className="big_title">SPONSORS</h2>
          </div>
          <div className="my_container">
            <div className="title_wrapper" data-aos="fade-up">
              <h2 className="title">OUR SPONSORS</h2>
              <img src={grass} alt="ball" className="grass_img" />
            </div>
          </div>
          <div
            className="my_container s_container p-0 mw-100"
            data-aos="fade-up"
          >
            <Swiper
              key={`s-swiper`}
              modules={[Autoplay]}
              loop={true}
              // loopedSlides={jerseyLogos.length}
              slidesPerView={5}
              spaceBetween={125}
              allowTouchMove={false}
              speed={3999}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 2.5,
                  spaceBetween: 10,
                },
                600: {
                  slidesPerView: 3.5,
                  spaceBetween: 10,
                },
                992: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
              }}
            >
              {jerseyLogoList}
            </Swiper>
          </div>
        </section>

        {width < 767 && (
          <section
            className="contact_cta"
            onClick={() => handleScrollDiv("contact", -100)}
          >
            <div className="btn_wrapper">
              <Link to={() => false}>
                <button className="submit_btn">Contact Us</button>
              </Link>
            </div>
          </section>
        )}
      </main>
      <WhatsApp />
      <Footer />
    </>
  );
};

export default Homepage;
